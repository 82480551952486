import React from "react";

const PosterDetails = () =>{

    return(

        <div>
            <h1>Poster Details</h1>
        </div>

    )
}

export default PosterDetails;