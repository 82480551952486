// AdminPage.js
import React from 'react';
import AdminComponent from "../../components/AdminComponent/adminComponent";
const AdminPage = () => {
  return (
    <div>
      <AdminComponent/>
    </div>
  );
};

export default AdminPage;
